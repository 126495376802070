<template>

  <Layout>
    <PageHeader :title="$route.meta.title + (guiaAuditada ? ' Auditada' : '')" :items="$route.meta.breadcrumbs" />

    <b-overlay
      :show="loading"
      variant="light"
      :opacity="0.85"
    >

    <div :loading="loading">
      <div v-if="!disabled && !guiaAuditada" class="hstack gap-3">
        <b-button pill variant="success" v-authorize="'lotesScLotesAuditar'" @click="salvar">
          <i class="uil uil-file-bookmark-alt"></i>
          Salvar
        </b-button>

        <b-button v-if="!pendenteRetorno" pill variant="primary" v-authorize="'lotesScLotesAuditar'" @click="encerrarAuditoria">
          <i class="uil uil-save"></i>
          Encerrar auditoria do item
        </b-button>

        <b-button pill variant="danger" class="ms-auto" @click="voltar">
          <i class="uil uil-times-circle"></i>
          Fechar
        </b-button>
      </div>
      
      <div class="card mt-4">
        <div class="card-body">
          <h5>Itens para glosa</h5>
          <b-table
            striped
            hover
            responsive="sm"
            :items="itensTrabalhados"
            :fields="fields"
          ><!-- TODO: b-tbl responsive conflitando com o select da edição -->
            <template #cell(tabelaDescritivo)="data">
              <div v-if="itemEmEdicao" style="width: 160px;">
                <f-selectsearch 
                  :name="`tabelaDescritivo${data.item.sequencial}`"
                  :ref="`tabelaDescritivo${data.item.sequencial}`"
                  :searchFunction="pesquisarTabelaDominio"
                  :searchAllOptions="true"
                  :removeOptionsOnSelect="false"
                  @select="addTabelaDominio"
                  :meta="{ sequencial: data.item.sequencial }"
                />
              </div>
              <div v-else
                :class="{
                  'dado-editado': data.item.tabelaCodigo != data.item.tabelaCodigoOri && data.item.tabelaCodigoOri
                }"
              >
                {{ data.item.tabelaDescritivo }}
              </div> 
            </template>

            <template #cell(procedimentoCodigo)="data">
              <div v-if="itemEmEdicao" style="width: 160px;">
                <f-selectsearch
                  :name="`procedimentoCodigo${data.item.sequencial}`"
                  :ref="`procedimentoCodigo${data.item.sequencial}`"
                  :disabled="!(model.tabelaId > 0)"
                  :searchFunction="pesquisarProcedimentoPorCodigo"
                  labelField="codigo"
                  @select="addProcedimentoPorCodigo"
                  :meta="{ sequencial: data.item.sequencial }"
                />
              </div>
              <div v-else
                :class="{
                  'dado-editado': data.item.procedimentoCodigo != data.item.procedimentoCodigoOri && data.item.procedimentoCodigoOri
                }"
              >
                {{ data.item.procedimentoCodigo }}
              </div> 
            </template>

            <template #cell(procedimentoDescricao)="data">
              <div v-if="itemEmEdicao" style="width: 250px;">
                <f-selectsearch
                  :name="`procedimentoDescricao${data.item.sequencial}`"
                  :ref="`procedimentoDescricao${data.item.sequencial}`"
                  :disabled="!(model.tabelaId > 0)"
                  :searchFunction="pesquisarProcedimentoPorDescricao"
                  labelField="descricao"
                  @select="addProcedimentoPorDescricao"
                  :meta="{ sequencial: data.item.sequencial }"
                />
              </div>
              <div v-else
                :class="{
                  'dado-editado': data.item.procedimentoDescricao != data.item.procedimentoDescricaoOri && data.item.procedimentoDescricaoOri
                }"
              >
                {{ data.item.procedimentoDescricao }}
              </div> 
            </template>

            <template #cell(actions)="data">

              <b-button v-if="!itemEmEdicao && !isModelEditado(data.item.sequencial)"
                variant="outline-primary"
                size="sm"
                title="Alterar procedimento"
                @click="editarLinha(data.item.sequencial)"
              >
                <i class="uil uil-exchange icon-size"></i>
              </b-button>
              
              <b-button v-if="itemEmEdicao"
                variant="outline-primary"
                size="sm"
                title="Aceitar alterações"
                @click="salvarLinha(data.item.sequencial)"
              >
                <i class="uil uil-save icon-size"></i>
              </b-button>
              
              <b-button v-if="!itemEmEdicao && isModelEditado(data.item.sequencial)"
                variant="outline-primary"
                size="sm"
                title="Desfazer alterações"
                @click="restaurarLinha(data.item.sequencial)"
              >
                <i class="uil uil-history icon-size"></i>
              </b-button>
              
            </template>

          </b-table>
          
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row gy-4">
            <div class="col-12 col-md-4">
              <h5>Glosa</h5>

              <div class="filtro-inline">
                <!-- As versões instaladas de bootstrap-vue e bootstrap estão incompatíveis; deixá-las compatível trazia outro bug, então corrigi por css -->
                <b-form-group>
                  <b-form-radio-group
                    id="auditoriaTipo"
                    v-model="auditoria.tipo"
                    :options="auditoria.tipos"
                    name="auditoriaTipo"
                    :disabled="disabled || guiaAuditada"
                  />
                </b-form-group>
              </div>

              <div class="row">

                <div class="col-8 px-2" v-if="auditoria.tipo == 'U'">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Glosa Quantidade(Qtd)</label>
                      <money
                        v-model.lazy="auditoria.valorUnitario"
                        class="text-end form-control"
                        :disabled="disabled || guiaAuditada"
                        v-debounce:450="calcularGlosa"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-8 px-2" v-if="auditoria.tipo == 'P'">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">% Percentual</label>
                      <money
                        v-model.lazy="auditoria.valorPercentual"
                        v-bind="inputPercentual"
                        class="text-end form-control"
                        :disabled="disabled || guiaAuditada"
                        v-debounce:450="calcularGlosa"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-8 px-2" v-if="auditoria.tipo == 'D'">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Valor Item</label>
                      <money
                        v-model.lazy="auditoria.desconto"
                        class="text-end form-control"
                        :disabled="disabled || guiaAuditada"
                        v-debounce:450="calcularGlosa"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4 px-2" v-show="auditoria.tipo != 'D'">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center" abbr="Quantidade">Qntd</label>
                      <b-form-input
                        v-model="auditoria.quantidade"
                        type="tel"
                        class="text-end"
                        :disabled="disabled || guiaAuditada"
                        v-debounce:450="calcularGlosa"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-12 col-md-8">
              <h5>Resultado da Glosa</h5>
              <div class="row align-items-end">
                
                <div class="col-6 col-md-3 px-2 m-0">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Glosa Quantidade(Qtd) auditado</label>
                      <money
                        v-model="model.valorUnitarioAuditado"
                        class="text-end form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-3 px-2">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Quantidade auditada</label>
                      <b-form-input v-model="modelQuantidadeAuditada" class="text-end" disabled/>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-3 px-2">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Total glosa</label>
                      <money
                        v-model="model.valorGlosa"
                        class="text-end form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-3 px-2">
                  <div class="card">
                    <div class="card-body p-2">
                      <label class="d-block text-center">Valor total auditado</label>
                      <money
                        v-model="model.valorTotalAuditado"
                        class="text-end form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row gy-4">
            <div class="col-12">
              <h5>Observações sobre a glosa</h5>
              <b-form-textarea
                id="observacaoGlosa"
                v-model="auditoria.observacaoGlosa"
                :disabled="disabled || guiaAuditada"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col pe-4">
              <b-form-group
                id="fieldset-filtroMotivos"
                label-cols="auto"
                label="Tabela de motivos"
                label-for="input-filtroMotivos"
              >
                <b-form-input v-if="!disabled && !guiaAuditada"
                  id="input-filtroMotivos"
                  v-model="carregarMotivosFiltro"
                  class="ms-2"
                  placeholder="Pesquisar motivo"
                  v-debounce:450="filtrarMotivos"
                />
              </b-form-group>
            </div>
            <div class="col-auto d-none d-md-block" style="width: 62px;"><!-- Espaço vazio --></div>
            <div class="col d-none d-md-block"><!-- Espaço vazio --></div>
          </div>
          <div class="row flex-column flex-md-row gy-2 gy-md-0">
            <div v-show="!disabled && !guiaAuditada" class="col">
              <b-form-select
                ref="selectMotivosNS"
                v-model="selecionadosLeft"
                :options="motivosNaoSelecionados"
                :disabled="!isAnyModelEditado()"

                v-validate="{required: isAnyModelEditado()}" 
                data-vv-as="Motivos"

                class="lista-motivos"
                multiple
                :select-size="7"
                @scroll.native="handleScrollMotivos"
              />
            </div>
            <div v-if="!disabled && !guiaAuditada"
              class="col-auto d-flex justify-content-center align-items-center"
              :style="{
                width: _verticalGroupButton ? '62px' : 'auto',
              }"
            >
              <b-button-group :vertical="_verticalGroupButton">
                <b-button variant="outline-primary" @click="remMotivoAll">
                  <i class="uil uil-angle-double-left icon-size"></i>
                </b-button>
                <b-button variant="outline-primary" @click="remMotivo">
                  <i class="uil uil-angle-left-b icon-size"></i>
                </b-button>
                <b-button variant="outline-primary" @click="addMotivo">
                  <i class="uil uil-angle-right-b icon-size"></i>
                </b-button>
              </b-button-group>
            </div>
            <div class="col">
              <b-form-select
                v-model="selecionadosRight"
                :options="motivosSelecionados"
                class="lista-motivos"
                multiple
              />
            </div>
          </div>
        </div>
      </div>
      
      <div class="card">
        <div class="card-body">
          <div class="row g-3 align-items-center">
            <f-switch
              name="pendenteRetorno"
              v-model="pendenteRetorno"
              :disabled="disabled"
              labelTrue="Pendente"
              labelFalse="Trânsito normalizado"
              label="Pendente retorno hospital"
              variant="danger"
              :cols="12"
              invertColor
            />
          </div>
          <div class="row" v-if="pendenteRetorno">
            <f-selectsearch v-authorize:disable="'admConvCriar'"
              label="Motivo da Pendência"
              placeholder="Pesquisar motivos de pendências do hospital"
              name="pesquisarPendenciaAuditoria"
              ref="pesquisarPendenciaAuditoria"
              :cols="12"
              :searchFunction="pesquisarMotivoPendenciaAuditoria"
              @select="addMotivoPendenciaAuditoria"
              :hideSelected="false"
              :disabled="disabled"
              :min-length="2"
            />
          </div>
        </div>
      </div>
      
      <div v-if="!disabled && !guiaAuditada" class="hstack gap-3">
        <b-button pill variant="success" v-authorize="'lotesScLotesAuditar'" @click="salvar">
          <i class="uil uil-file-bookmark-alt"></i>
          Salvar
        </b-button>

        <b-button v-if="!pendenteRetorno" pill variant="primary" v-authorize="'lotesScLotesAuditar'" @click="encerrarAuditoria">
          <i class="uil uil-save"></i>
          Encerrar auditoria do item
        </b-button>

        <b-button pill variant="danger" class="ms-auto" @click="voltar">
          <i class="uil uil-times-circle"></i>
          Fechar
        </b-button>
      </div>

      <div class="card mt-4" v-if="model.equipe">
        <div class="card-body">
          <h5>Identificação Equipe</h5>
          <div class="d-block" v-for="(eleEquipe, idx) in model.equipe" :key="`eqpIdx${idx}`">
            <div class="row">
              <div class="col-lg-3 mb-1">
                <label><strong>Grau de participação:</strong> {{ eleEquipe.grauParticipacao }}</label>
              </div>
              <div class="col-lg-3 mb-1">
                <label><strong>CPF:</strong> {{ eleEquipe.cpf }}</label>
              </div>
              <div class="col-lg-3 mb-1">
                <label><strong>Nome:</strong> {{ eleEquipe.nome }}</label>
              </div>
              <div class="col-lg-3 mb-1">
                <label><strong>Conselho:</strong> {{ eleEquipe.conselho }}</label>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 mb-1"><strong>Número conselho profissional:</strong> {{ eleEquipe.numeroConselhoProf }}</div>
              <div class="col-lg-3 mb-1"><strong>UF:</strong> {{ eleEquipe.uf }}</div>
              <div class="col-lg-3 mb-1"><strong>CBOS:</strong> {{ eleEquipe.cbos }}</div>
            </div>
          </div>
          
        </div>
      </div>

    </div>

      <div class="card">
        <div class="card-footer">
          <button class="btn btn-info px-5" type="button" @click="voltar">Voltar</button>
        </div>
      </div>
    </b-overlay>
  </Layout>

  
</template>

<script>
import appConfig from "@/app.config";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import {
  getItemGuiaDetalhe, calcularItemGuia, atualizarItemGuia, getLabelsMotivos, pesquisarMotivoPendenciaAuditoria,
  getAllTabelaDominio, getProcedimento
} from "../../../services/faturamento.service";
import {
  trataErro
} from "../../../utils/tratar.retornos.js";
import { Money } from 'v-money'
import { messageConfirm, messageError, messageSuccess, messageWarning } from "../../../utils/messages";
import FSelectsearch from '@/components/Form/SelectSearch';
import FSwitch from '@/components/Form/Switch';

export default {
  page: {
    title: "Formulário de auditoria",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Money, Layout, PageHeader, FSwitch, FSelectsearch,
  },
  data() {
    return {
      pesquisarMotivoPendenciaAuditoria,

      disabled: true,
      loading: true,
      itemEmEdicao: false,
      guiaAuditada: false,
      model: {
        sequencial: null,
        tipo: null,
        data: null,
        horaIni: null,
        horaFim: null,
        tabelaId: null,
        tabelaCodigo: null,
        tabelaTermo: null,
        tabelaDescritivo: null,
        procedimentoId: null,
        procedimentoCodigo: null,
        procedimentoDescricao: null,
        procedimentoValor: null,
        valorUnitario: 0.0,
        valorUnitarioAuditado: 0.0,
        quantidade: null,
        quantidadeAuditada: null,
        reducaoAcrescimo: null,
        reducaoAcrescimoAuditado: null,
        valorTotal: 0.0,
        valorTotalAuditado: 0.0,
        valorGlosa: 0.0,
        status: null,
        statusGuia: null,
        motivos: [],
        equipe: [],
        motivoPendenciaAuditoriaId: null,
        observacaoGlosa: '',
      },
      auditoria: {
        valorUnitario: 0.0,
        desconto: 0.0,
        valorPercentual: 0.0,
        quantidade: 0.0,
        tipo: 'U',
        tipos: [
          {
            text: 'Glosa Quantidade(Qtd)',
            value: 'U',
          },{
            text: '% Percentual',
            value: 'P',
          },{
            text: 'Valor Item',
            value: 'D',
          }
        ],
        observacaoGlosa: '',
      },
      motivos: [],
      motivosFiltrados: [],
      pendenteRetornoAux: false,
      selecionadosLeft: [],
      selecionadosRight: [],
      carregarMotivosFiltro: '',
      carregarMotivosPagina: 1,
      carregarMotivosComFiltroPagina: -1,
      ignDblReq: null,
      inputPercentual: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '%',
        precision: 2,
        masked: false
      },
      fields: [
        {
          label: 'Data',
          key: 'data',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.data(value);
          },
        },
        {
          label: 'Hora Inicial',
          key: 'horaIni',
        },
        {
          label: 'Hora Final',
          key: 'horaFim',
        },
        {
          label: 'Tabela',
          key: 'tabelaDescritivo',
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.tabelaCodigo != item.tabelaCodigoOri && item.tabelaCodigoOri) {
              attr.title = 'Original: ' + item.tabelaCodigoOri;
            }
            return attr;
          },
        },
        {
          label: 'Código',
          key: 'procedimentoCodigo',
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.procedimentoCodigo != item.procedimentoCodigoOri && item.procedimentoCodigoOri) {
              attr.title = 'Original: ' + item.procedimentoCodigoOri;
            }
            return attr;
          },
        },
        {
          label: 'Descrição',
          key: 'procedimentoDescricao',
          tdAttr: (value, key, item) => {
            let attr = {};
            if (item.procedimentoDescricao != item.procedimentoDescricaoOri && item.procedimentoDescricaoOri) {
              attr.title = 'Original: ' + item.procedimentoDescricaoOri;
            }
            return attr;
          },
        },
        {
          label: 'Valor Unitário',
          headerAbbr: 'Valor Unitário',
          key: 'valorUnitario',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
        },
        {
          label: 'Qtd',
          headerAbbr: 'Quantidade',
          key: 'quantidade',
          formatter: (value/*, key, item*/) => {
            if (!value) return '';
            return value.toString().replace('.', ',');
          },
        },
        {
          label: 'Redução / Acréscimo',
          key: 'reducaoAcrescimo',
          formatter: (value/*, key, item*/) => {
            if (!value) return '';
            return value.toFixed(4).replace('.', ',');
          },
        },
        {
          label: 'Valor total',
          headerAbbr: 'Valor Total',
          key: 'valorTotal',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
        },
        {
          label: 'Qtd Auditada',
          headerAbbr: 'Quantidade Auditada',
          key: 'quantidadeAuditada',
          formatter: (value/*, key, item*/) => {
            if (!value) return '';
            return value.toString().replace('.', ',');
          },
        },
        {
          label: 'Vlr Glosa',
          headerAbbr: 'Valor Glosa',
          key: 'valorGlosa',
          formatter: (value/*, key, item*/) => {
            return this.$options.filters.toCurrency(value);
          },
        },
        {
          label: 'Vlr total auditado',
          headerAbbr: 'Valor Total Auditado',
          key: 'valorTotalAuditado',
          formatter: (value/*, key, item*/) => {
            if (!value) return '';
            return this.$options.filters.toCurrency(value);
          },
        },
      ],
    };
  },
  watch: {
    'auditoria.tipo'(tipo) {
      if (tipo != 'U') this.auditoria.valorUnitario = 0.0;
      if (tipo != 'P') this.auditoria.valorPercentual = 0.0;
      if (tipo != 'D') {
        this.auditoria.desconto = 0.0;
        
        this.auditoria.quantidade = this.model.quantidadeAuditada;
        if (this.auditoria.quantidade === null) {
          this.auditoria.quantidade = this.model.quantidade;
        }
      }

      if (tipo == 'U') {
        if (this.model.valorUnitarioAuditado) {
          this.auditoria.valorUnitario = this.model.valorUnitarioAuditado;
        } else {
          this.auditoria.valorUnitario = this.model.valorUnitario;
        }
      }
    }
  },
  computed: {
    _verticalGroupButton() { // Melhorar (prob é o vertical do button group)
      return window.screen.width >= 758
    },
    itensTrabalhados() {
      return [ this.model ];
    },
    modelQuantidadeAuditada() {
      var qntdAud = this.model.quantidadeAuditada;
      if (!qntdAud) return '';
      return qntdAud.toString().replace('.', ',');
    },
    motivosNaoSelecionados() {
      let nSel = this.carregarMotivosFiltro ? this.motivosFiltrados : this.motivos;
      return nSel.filter((item) => {
        return !this.model.motivos.includes(item.value);
      });
    },
    motivosSelecionados() {
      return this.motivos.filter((item) => {
        return this.model.motivos.includes(item.value);
      });
    },
    pendenteRetorno: {
      get() {
        return this.model.motivoPendenciaAuditoriaId > 0 || this.pendenteRetornoAux;
      },
      set(v) {
        if (v) {
          this.pendenteRetornoAux = true;
        } else {
          this.pendenteRetornoAux = false;
          this.model.motivoPendenciaAuditoriaId = null;
        }
      }
    }
  },
  created() {
    this.$route.meta.breadcrumbs[2].text = this.$route.params.numeroLote
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;
    this.loading = true;

    getItemGuiaDetalhe(
      this.$route.params.arquivoId,
      this.$route.params.guiaIndex,
      JSON.parse(this.$route.params.itemGuiaIds)
    ).then(({ data }) => {
      this.preencherModel(data);

      this.model.tabelaId = null;
      this.model.procedimentoId = null;
      this.model.procedimentoValor = null;

      this.motivos = data.motivosObjs.map(this.formatarMotivos);
      this.carregarMotivos()

      this.guiaAuditada = this.model.statusGuia == 'A'; // translateStatusAuditoria

      this.auditoria.quantidade = this.model.quantidadeAuditada;
      if (this.auditoria.quantidade === null) {
        this.auditoria.quantidade = this.model.quantidade;
      }
      if (this.model.valorPercentualAuditado > 0) {
        this.auditoria.tipo = 'P';
        this.auditoria.valorPercentual = this.model.valorPercentualAuditado * 100;
      } else if (this.model.descontoAuditado > 0) {
        this.auditoria.tipo = 'D';
        this.auditoria.desconto = this.model.descontoAuditado;
      } else if (this.model.valorUnitarioAuditado) {
        this.auditoria.tipo = 'U';
        this.auditoria.valorUnitario = this.model.valorUnitarioAuditado;
      } else {
        this.auditoria.tipo = 'U';
        this.auditoria.valorUnitario = this.model.valorUnitario;
      }
      this.auditoria.observacaoGlosa = this.model.observacaoGlosa;

      this.loading = false;

      this.$nextTick(() => {
        if (this.pendenteRetorno) {
          this.$refs.pesquisarPendenciaAuditoria.setOptions([ this.model.motivoPendenciaAuditoriaObj ]);
          this.$refs.pesquisarPendenciaAuditoria.setInitialValue(this.model.motivoPendenciaAuditoriaId);
        }
      });
    })

    if (!this.disabled) {
      this.fields.push({
        label: '',
        key: 'actions',
      });
    }
  },
  methods: {
    // Motivo
    addMotivo() {
      this.model.motivos = this.model.motivos.concat(this.selecionadosLeft);
      this.model.motivos = this.model.motivos.filter((item, pos) => this.model.motivos.indexOf(item) == pos);

      // Caso muitos sejam puxados, recarrega os motivos (podem haver mais no banco).
      if (this.motivosNaoSelecionados.length < 10) {
        this.carregarMotivos();
      }
    },
    remMotivo() {
      for (let i = this.selecionadosRight.length - 1; i >= 0; i--) {
        let idx = this.model.motivos.indexOf(this.selecionadosRight[i]);
        if (idx != -1) this.model.motivos.splice(idx, 1);
      }
    },
    remMotivoAll() {
      this.model.motivos = [];
    },

    // Métodos
    addMotivoPendenciaAuditoria(selected) {
      this.model.motivoPendenciaAuditoriaId = selected.id;
    },
    addProcedimento(procedimento) {
      if (procedimento) {
        this.model.procedimentoId = procedimento.id;
        this.model.procedimentoCodigo = procedimento.codigo;
        this.model.procedimentoDescricao = procedimento.descricao;
        this.model.procedimentoValor = procedimento.valorProcedimento;
      } else {
        this.model.procedimentoId = null;
        this.model.procedimentoCodigo = '';
        this.model.procedimentoDescricao = '';
        this.model.procedimentoValor = null;
      }
    },
    addProcedimentoPorCodigo(selected, meta) {
      this.$refs['procedimentoDescricao' + meta.sequencial].setInitialValue(selected);
      this.addProcedimento(selected)
    },
    addProcedimentoPorDescricao(selected, meta) {
      this.$refs['procedimentoCodigo' + meta.sequencial].setInitialValue(selected);
      this.addProcedimento(selected)
    },
    addTabelaDominio(tabela_dominio, meta) {
      if (!tabela_dominio || this.model.tabelaId != tabela_dominio.id) {
        this.$refs['procedimentoCodigo' + meta.sequencial].setInitialValue({});
        this.$refs['procedimentoDescricao' + meta.sequencial].setInitialValue({});
        this.addProcedimento(null);
      }

      if (tabela_dominio) {
        this.model.tabelaId = tabela_dominio.id;
        this.model.tabelaDescritivo = tabela_dominio.label;
      } else {
        this.model.tabelaId = null;
        this.model.tabelaDescritivo = '';
      }
    },
    calcularGlosa() {
      this.loading = true;

      let representacao = JSON.parse(sessionStorage.getItem('representacao'));
      let usuarioRepresentadoId = null;
      if (
        representacao &&
        representacao.arquivoId == this.$route.params.arquivoId &&
        representacao.guiaIndex == this.$route.params.guiaIndex &&
        representacao.numeroLote == this.$route.params.numeroLote
      ) {
        usuarioRepresentadoId = representacao.representando.id;
      }

      var glosa = {
        valorUnitarioAuditado: this.auditoria.valorUnitario,
        descontoAuditado: this.auditoria.desconto,
        valorPercentualAuditado: this.auditoria.valorPercentual / 100,
        quantidadeAuditada: this.$options.filters.toDecimal(this.auditoria.quantidade),
        motivos: this.model.motivos,
        usuarioRepresentadoId,
      };

      calcularItemGuia(
        this.$route.params.arquivoId,
        this.$route.params.guiaIndex,
        JSON.parse(this.$route.params.itemGuiaIds),
        glosa
      ).then(({ data }) => {
        // Evitar substituir dados que já foram alterados (em memória).
        delete data.tabelaDescritivo;
        delete data.procedimentoCodigo;
        delete data.procedimentoDescricao;

        this.preencherModel(data);
      }).catch(err => {
        trataErro(err, this)
      }).finally(() => {
        this.loading = false;
      });
    },
    carregarMotivos() {
      if (this.ignDblReq == null) {
        this.ignDblReq = new AbortController();
      } else {
        this.ignDblReq.abort();
      }

      let comFiltro = !!this.carregarMotivosFiltro;
      
      if ( // O !! é pq o builder está tirando os parênteses.
        !!(comFiltro && this.carregarMotivosComFiltroPagina != -1) ||
        !!(!comFiltro && this.carregarMotivosPagina != -1)
      ) {
        getLabelsMotivos(this.carregarMotivosParam(comFiltro), this.ignDblReq).then(({ data }) => {
          if (data.resultado.length > 0) {
            let selectEl = this.$refs.selectMotivosNS.$el;
            let scrollYPos = selectEl.scrollTop;

            if (this.carregarMotivosFiltro) {
              this.carregarMotivosComFiltroPagina = data.paginacao.pagina + 1;
              if (data.paginacao.pagina == 1) {
                this.motivosFiltrados = data.resultado.map(this.formatarMotivos);
              } else {
                this.motivosFiltrados = this.motivosFiltrados.concat(data.resultado.map(this.formatarMotivos));
              }
            } else {
              this.carregarMotivosPagina = data.paginacao.pagina + 1;
              this.motivos = this.motivos.concat(data.resultado.map(this.formatarMotivos));
              this.motivos = this.motivos.filter(function (value, index, self) {
                for (let i = 0; i < self.length; i++) {
                  if (self[i].value === value.value) {
                    return i === index;
                  }
                }
                return false;
              });
            }

            this.$nextTick(() => {
              selectEl.scrollTop = scrollYPos;
              
              if (this.motivosNaoSelecionados.length < 10) {
                this.carregarMotivos();
              }
            });
          } else {
            if (this.carregarMotivosFiltro) {
              this.carregarMotivosComFiltroPagina = -1;
            } else {
              this.carregarMotivosPagina = -1;
            }
          }
        });
      }
    },
    carregarMotivosParam(comFiltro) {
      return {
        filtro: this.carregarMotivosFiltro,
        ativo: true,
        paginacao: {
          pagina: comFiltro ? this.carregarMotivosComFiltroPagina : this.carregarMotivosPagina,
        },
      }
    },
    editarLinha(/*sequencial*/) {
      this.itemEmEdicao = !this.itemEmEdicao;
    },
    filtrarMotivos() {
      this.carregarMotivosComFiltroPagina = 1;
      this.carregarMotivos();
    },
    formatarMotivos(motivo) {
      return {
        value: motivo.id,
        text: motivo.label,
        comentObrigatorio: motivo.comentObrigatorio
      }
    },
    handleScrollMotivos(evt) {
      if (evt.target.scrollTop + 50 > evt.target.scrollTopMax) {
        this.carregarMotivos();
      }
    },
    isModelEditado(sequencial) {
      if (sequencial > -1) { // TODO: localizar no arr de itens editados
        return (
          (this.model.tabelaCodigo != this.model.tabelaCodigoOri && this.model.tabelaCodigoOri) ||
          (this.model.procedimentoCodigo != this.model.procedimentoCodigoOri && this.model.procedimentoCodigoOri) ||
          (this.model.procedimentoDescricao != this.model.procedimentoDescricaoOri && this.model.procedimentoDescricaoOri)
        )
      }
      return false;
    },

    hasNumChange(v1, v2) {
      return v1 != null && v1 != undefined && v1 != v2 
    },

    isAnyModelEditado() {
      var result = Boolean(
          (this.model.tabelaCodigo != this.model.tabelaCodigoOri && this.model.tabelaCodigoOri) ||
          (this.model.procedimentoCodigo != this.model.procedimentoCodigoOri && this.model.procedimentoCodigoOri) ||
          (this.model.procedimentoDescricao != this.model.procedimentoDescricaoOri && this.model.procedimentoDescricaoOri) ||
          this.hasNumChange(this.model.valorUnitarioAuditado, this.model.valorUnitario) ||
          this.hasNumChange(this.model.reducaoAcrescimoAuditado, this.model.reducaoAcrescimo) ||
          this.hasNumChange(this.model.valorTotalAuditado, this.model.valorTotal) ||
          this.hasNumChange(this.model.quantidadeAuditada, this.model.quantidade)
        )

        if (!result && this.model.motivos.length > 0) {
          this.model.motivos = []
        }
        return result;
    },
    pesquisarProcedimentoPorCodigo(query) {
      return getProcedimento({
        filtrarCodigo: query,
        loteId: this.$route.params.arquivoId,
        tabelaIds: [ this.model.tabelaId ],
        ativo: true,
        ordenacao: {
          campo: 'codigo'
        },
      });
    },
    pesquisarProcedimentoPorDescricao(query) {
      return getProcedimento({
        filtrarDescricao: query,
        loteId: this.$route.params.arquivoId,
        tabelaIds: [ this.model.tabelaId ],
        ativo: true,
        ordenacao: {
          campo: 'descricao'
        },
      });
    },
    pesquisarTabelaDominio(query) {
      return getAllTabelaDominio({
        filtro: query,
        paginacao: {
          pagina: 1,
          itensPorPagina: 999,
        },
        ativo: true,
      })
    },
    preencherModel(itemTrabalhado) {
      let propsDecimal = [
        'valorUnitario',
        'valorUnitarioAuditado',
        'valorTotal',
        'valorTotalAuditado',
        'valorGlosa',
        'motivoPendenciaAuditoriaId',
      ];
      let propsIgnore = [
        'quantidade',
        'quantidadeAuditada',
      ];
      for (let key in itemTrabalhado) {
        if (propsIgnore.includes(key)) {
          this.model[key] = itemTrabalhado[key];
        } else if (propsDecimal.includes(key)) {
          this.model[key] = itemTrabalhado[key] || 0.0;
        } else {
          this.model[key] = itemTrabalhado[key] || '';
        }
      }
    },
    restaurarLinha(/*sequencial*/) {
      this.model.tabelaId = null;
      this.model.procedimentoId = null;
      this.model.procedimentoValor = null;
      this.model.tabelaCodigo = this.model.tabelaCodigoOri;
      this.model.tabelaDescritivo = this.model.tabelaDescritivoOri;
      this.model.procedimentoCodigo = this.model.procedimentoCodigoOri;
      this.model.procedimentoDescricao = this.model.procedimentoDescricaoOri;
    },
    salvarLinha(sequencial) {
      if (this.model.tabelaId > 0 && this.model.procedimentoId > 0) {
        if (this.model.procedimentoValor > 0) {
          this.auditoria.tipo = 'U';
          this.$nextTick(() => {
            this.auditoria.valorUnitario = this.model.procedimentoValor;
            this.calcularGlosa();
          })
        } else {
          this.calcularGlosa();
        }
      } else {
        messageWarning(this, 'Tabela e Procedimento são obrigatórios')
        this.restaurarLinha(sequencial);
      }
      this.itemEmEdicao = false;
    },
    voltar() {
      if (!window.history || !window.history.length) {
        this.$router.push({
          name: 'faturamento.lotes.detalheguia.' + (this.disabled ? 'visualizar' : 'auditar'),
          params: {
            arquivoId: this.$route.params.arquivoId,
            guiaIndex: this.$route.params.guiaIndex,
            numeroLote: this.$route.params.numeroLote,
          },
        });
      } else {
        this.$router.go(-1); // Tentar contornar o erro q acontece ao estar editando a guia, visualizar o item e ao voltar perder o status de "editando"
      }
    },

    // Auditoria
    encerrarAuditoria() {
      messageConfirm(this, "Ao encerrar a auditoria o item irá para o status Auditado. Porém enquanto a guia não for encerrada o item ainda poderá ser editado",
      "Deseja encerrar a auditoria?").then((result) => {
        if (result.isConfirmed) {
          this.salvarItemGuia(true)
        }
      })
    },
    salvar() {
      this.salvarItemGuia(false)
    },
    salvarItemGuia(encerrarAuditoria) {

      if (this.motivosSelecionados.filter(m => m.comentObrigatorio).length > 0 && (!this.auditoria.observacaoGlosa || this.auditoria.observacaoGlosa == '')) {
        messageError(this, "Você deve preencher o campo observações para poder salvar a auditoria.")
        return;
      }

      if (this.isAnyModelEditado() && (this.motivosSelecionados == null || this.motivosSelecionados.length == 0)) {
        messageError(this, "Selecione ao menos um motivo para a glosa do item.")
        return;
      }

      this.loading = true;

      let representacao = JSON.parse(sessionStorage.getItem('representacao'));
      let usuarioRepresentadoId = null;
      if (
        representacao &&
        representacao.arquivoId == this.$route.params.arquivoId &&
        representacao.guiaIndex == this.$route.params.guiaIndex &&
        representacao.numeroLote == this.$route.params.numeroLote
      ) {
        usuarioRepresentadoId = representacao.representando.id;
      }

      var glosa = {
        valorUnitarioAuditado: this.auditoria.valorUnitario,
        descontoAuditado: this.auditoria.desconto,
        valorPercentualAuditado: this.auditoria.valorPercentual / 100,
        quantidadeAuditada: this.$options.filters.toDecimal(this.auditoria.quantidade),
        motivos: this.model.motivos,
        motivoPendenciaAuditoriaId: parseInt(this.model.motivoPendenciaAuditoriaId),
        observacaoGlosa: this.auditoria.observacaoGlosa,
        tabelaId: this.model.tabelaId,
        procedimentoId: this.model.procedimentoId,
        usuarioRepresentadoId,
      };
      if (isNaN(glosa.motivoPendenciaAuditoriaId))
      {
        glosa.motivoPendenciaAuditoriaId = null;
      }

      atualizarItemGuia(
        encerrarAuditoria,
        this.$route.params.arquivoId,
        this.$route.params.guiaIndex,
        JSON.parse(this.$route.params.itemGuiaIds),
        glosa
      ).then(({ data }) => {
        if (data) {
          messageSuccess(this, "Item da guia salvo")
          this.voltar();
        } else {
          messageError(this, "Falha ao salvar item da guia")
        }
      }).catch(err => {
        trataErro(err, this);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lista-motivos {
  width: 100%;
  height: 150px;
}
</style>